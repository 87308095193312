import styled from 'styled-components';
import { device } from '@/Components/styles';
import { Container, Row, Button } from 'react-bootstrap';

const waveOpacity = '0.3';

const waveParams = [
    {
        begin: '0s',
        basePath: `M0 0L 0 745Q 179 727 358 710T 716 652T 1075 554T 1433 378T 1792 295L 1792 0 Z`,
        animationPaths: [
            `M0 0L 0 737Q 179 632 358 602T 716 596T 1075 444T 1433 387T 1792 400L 1792 0 Z`,
            `M0 0L 0 706Q 179 717 358 690T 716 661T 1075 505T 1433 481T 1792 383L 1792 0 Z`,
            `M0 0L 0 746Q 179 730 358 713T 716 654T 1075 558T 1433 378T 1792 291L 1792 0 Z`,
            `M0 0L 0 737Q 179 632 358 602T 716 596T 1075 444T 1433 387T 1792 400L 1792 0 Z`,
        ],
    },
    {
        begin: '-2s',
        basePath: `M0 0L 0 770Q 179 774 358 740T 716 616T 1075 468T 1433 447T 1792 312L 1792 0 Z`,
        animationPaths: [
            `M0 0L 0 797Q 179 773 358 735T 716 648T 1075 448T 1433 430T 1792 308L 1792 0 Z`,
            `M0 0L 0 767Q 179 766 358 741T 716 617T 1075 564T 1433 493T 1792 436L 1792 0 Z`,
            `M0 0L 0 725Q 179 776 358 749T 716 560T 1075 503T 1433 477T 1792 319L 1792 0 Z`,
            `M0 0L 0 797Q 179 773 358 735T 716 648T 1075 448T 1433 430T 1792 308L 1792 0 Z`,
        ],
    },
    {
        begin: '-4s',
        basePath: `M0 0L 0 765Q 179 771 358 752T 716 618T 1075 553T 1433 466T 1792 401L 1792 0 Z`,
        animationPaths: [
            `M0 0L 0 780Q 179 769 358 752T 716 612T 1075 578T 1433 450T 1792 418L 1792 0 Z`,
            `M0 0L 0 713Q 179 777 358 751T 716 638T 1075 469T 1433 517T 1792 347L 1792 0 Z`,
            `M0 0L 0 714Q 179 741 358 710T 716 660T 1075 512T 1433 455T 1792 303L 1792 0 Z`,
            `M0 0L 0 780Q 179 769 358 752T 716 612T 1075 578T 1433 450T 1792 418L 1792 0 Z`,
        ],
    },
    {
        begin: '-6s',
        basePath: `M0 0L 0 777Q 179 701 358 667T 716 618T 1075 524T 1433 395T 1792 365L 1792 0 Z`,
        animationPaths: [
            `M0 0L 0 759Q 179 679 358 639T 716 642T 1075 511T 1433 439T 1792 409L 1792 0 Z`,
            `M0 0L 0 780Q 179 706 358 672T 716 613T 1075 527T 1433 386T 1792 355L 1792 0 Z`,
            `M0 0L 0 716Q 179 781 358 764T 716 559T 1075 552T 1433 516T 1792 433L 1792 0 Z`,
            `M0 0L 0 759Q 179 679 358 639T 716 642T 1075 511T 1433 439T 1792 409L 1792 0 Z`,
        ],
    },
    {
        begin: '-8s',
        basePath: `M0 0L 0 731Q 179 727 358 700T 716 574T 1075 565T 1433 415T 1792 282L 1792 0 Z`,
        animationPaths: [
            `M0 0L 0 734Q 179 665 358 634T 716 588T 1075 577T 1433 408T 1792 268L 1792 0 Z`,
            `M0 0L 0 742Q 179 786 358 761T 716 523T 1075 583T 1433 433T 1792 259L 1792 0 Z`,
            `M0 0L 0 715Q 179 649 358 618T 716 641T 1075 542T 1433 391T 1792 313L 1792 0 Z`,
            `M0 0L 0 734Q 179 665 358 634T 716 588T 1075 577T 1433 408T 1792 268L 1792 0 Z`,
        ],
    },
];

const Wave = ({ basePath, begin, animationPaths }) => {
    return (
        <path d={basePath} fill="url(#grad)" opacity={waveOpacity}>
            <animate
                attributeName="d"
                dur="10s"
                repeatCount="indefinite"
                keyTimes="0;0.333;0.667;1"
                calcmod="spline"
                keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                begin={begin}
                values={animationPaths.join(';')}
            ></animate>
        </path>
    );
};

const StyledSVG = styled.svg`
    z-index: -100;
    width: 100%;
    min-height: 400px;
    ${device.sm} {
        min-height: 500px;
    }
`;

export const TopWaves = () => {
    return (
        <div>
            <StyledSVG xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1792 852">
                <g transform="">
                    <linearGradient id="grad" x1="0" x2="1" y1="0" y2="0">
                        <stop stopColor="#1575E2" offset="-.1"></stop>
                        <stop stopColor="#E28215" offset=".9"></stop>
                    </linearGradient>
                    {waveParams.map((wave) => {
                        return <Wave {...wave} key={wave.basePath} />;
                    })}
                </g>
            </StyledSVG>
        </div>
    );
};

const MathSymbolsSVG = styled.svg`
    position: absolute;
    fill: #c3ced6;
    stroke: #c3ced6;
    opacity: 0.3;
    z-index: -10;

    top: ${(props) => (props.position === 'top' ? '30px' : null)};
    right: ${(props) => (props.position === 'top' ? '-150px' : null)};
    bottom: ${(props) => (props.position === 'top' ? null : '400px')};
    left: ${(props) => (props.position === 'top' ? null : '-100px')};

    width: 400px;
    ${device.sm} {
        width: 600px;
    }
    ${device.md} {
        width: 600px;
        top: ${(props) => (props.position === 'top' ? '30px' : null)};
        right: ${(props) => (props.position === 'top' ? '-100px' : null)};
        bottom: ${(props) => (props.position === 'top' ? null : '50px')};
        left: ${(props) => (props.position === 'top' ? null : '-100px')};
    }
    ${device.lg} {
        width: 1000px;
    }
    ${device.xl} {
        width: 1200px;
        top: ${(props) => (props.position === 'top' ? '-250px' : null)};
        right: ${(props) => (props.position === 'top' ? '-400px' : null)};
        bottom: ${(props) => (props.position === 'top' ? null : '40px')};
        left: ${(props) => (props.position === 'top' ? null : '-400px')};
    }
`;

export const MathSymbols = ({ position }) => {
    return (
        <MathSymbolsSVG
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 834 819"
            xmlSpace="preserve"
            preserveAspectRatio="none"
            position={position}
        >
            <g>
                <path
                    d="M320.7,193.2L320.7,193.2c13.05,0,23.63,10.58,23.63,23.63s-10.58,23.63-23.63,23.63h-80.29c-0.06,0-0.12,0-0.18,0
            c-2.57,0.08-4.6,2.23-4.52,4.8v80.29c0,13.05-10.58,23.63-23.63,23.63s-23.63-10.58-23.63-23.63v-80.29c0-0.02,0-0.05,0-0.07
            c-0.02-2.58-2.12-4.65-4.7-4.63h-80.29c-13.05,0-23.63-10.58-23.63-23.63s10.58-23.63,23.63-23.63h80.29c0.02,0,0.05,0,0.07,0
            c2.58-0.02,4.65-2.12,4.63-4.7v-80.39l0,0c0-13.05,10.58-23.63,23.63-23.63s23.63,10.58,23.63,23.63v80.39c0,0.02,0,0.05,0,0.07
            c0.02,2.58,2.12,4.65,4.7,4.63h80.29V193.2z"
                />
                <path
                    d="M724.59,636.32H507.32c-13.05,0-23.63-10.58-23.63-23.63c0-13.05,10.58-23.63,23.63-23.63h217.26
            c13.05,0,23.63,10.58,23.63,23.63C748.22,625.74,737.64,636.32,724.59,636.32L724.59,636.32z"
                />
                <path
                    d="M248.7,608.91c-1.79,1.83-1.76,4.76,0.07,6.55l56.78,56.81c8.73,9.14,8.73,23.52,0,32.65c-9.02,9.44-23.98,9.78-33.42,0.76
            l-56.81-56.78c-0.02-0.02-0.04-0.04-0.07-0.07c-1.83-1.79-4.76-1.76-6.55,0.07l-56.81,56.78c-0.25,0.26-0.5,0.52-0.76,0.76
            c-9.44,9.02-24.4,8.68-33.42-0.76c-9.02-9.44-8.68-24.4,0.76-33.42l56.78-56.81c0.02-0.02,0.04-0.04,0.07-0.07
            c1.79-1.83,1.76-4.76-0.07-6.55l-56.78-56.81c-8.73-9.14-8.73-23.52,0-32.65c9.02-9.44,23.98-9.78,33.42-0.76l56.99,56.78
            c0.02,0.02,0.04,0.04,0.07,0.07c1.83,1.79,4.76,1.76,6.55-0.07l56.64-56.67c9.14-8.73,23.52-8.73,32.65,0
            c9.44,9.02,9.78,23.98,0.76,33.42l-56.78,56.71C248.74,608.87,248.72,608.89,248.7,608.91z"
                />
                <g>
                    <path d="M615.92,84.15c53.95,0.87,54.02,81.13,0.07,82.09C562.03,165.36,561.98,85.11,615.92,84.15z" />
                    <path d="M615.92,348.72c-53.95-0.87-54.02-81.13-0.07-82.09C669.8,267.51,669.85,347.76,615.92,348.72z" />
                    <path
                        d="M724.56,240.07h-217.3c-13.05,0-23.64-10.58-23.64-23.64s10.58-23.64,23.64-23.64h217.3
                C755.41,192.92,755.53,239.94,724.56,240.07L724.56,240.07z"
                    />
                </g>
                <path
                    fill="none"
                    strokeWidth="16"
                    strokeMiterlimit="10"
                    d="M276.43,782H149.4c-59.04,0-106.9-47.86-106.9-106.9V548.07c0-59.04,47.86-106.9,106.9-106.9h127.03
            c59.04,0,106.9,47.86,106.9,106.9V675.1C383.33,734.14,335.47,782,276.43,782z"
                />
                <path
                    fill="none"
                    strokeWidth="16"
                    strokeMiterlimit="10"
                    d="M677.15,387.33H550.12c-59.04,0-106.9-47.86-106.9-106.9V153.4c0-59.04,47.86-106.9,106.9-106.9h127.03
            c59.04,0,106.9,47.86,106.9,106.9v127.03C784.05,339.47,736.19,387.33,677.15,387.33z"
                />
                <path
                    fill="none"
                    strokeWidth="16"
                    strokeMiterlimit="10"
                    d="M276.43,386.46H149.4c-59.04,0-106.9-47.86-106.9-106.9V152.53c0-59.04,47.86-106.9,106.9-106.9h127.03
            c59.04,0,106.9,47.86,106.9,106.9v127.03C383.33,338.6,335.47,386.46,276.43,386.46z"
                />
                <path
                    fill="none"
                    strokeWidth="16"
                    strokeMiterlimit="10"
                    d="M677.12,781H550.09c-59.04,0-106.9-47.86-106.9-106.9V547.07c0-59.04,47.86-106.9,106.9-106.9h127.03
            c59.04,0,106.9,47.86,106.9,106.9V674.1C784.01,733.14,736.15,781,677.12,781z"
                />
            </g>
        </MathSymbolsSVG>
    );
};

export const TopSpace = styled.div`
    z-index: -100;
    width: 100%;
    min-height: 480px;
    ${device.sm} {
        min-height: 600px;
    }
`;

export const ScreenContainer = styled.div`
    position: absolute;
    z-index: 10;
    width: 400px;
    top: 220px;
    right: -250px;
    ${device.sm} {
        width: 500px;
        top: 130px;
        right: -250px;
    }
    ${device.md} {
        width: 500px;
        top: 150px;
        right: -170px;
    }
    ${device.lg} {
        width: 700px;
        top: 150px;
        right: -230px;
    }
    ${device.xl} {
        width: 700px;
        top: 150px;
        right: -190px;
    }
`;

export const VideoContainer = styled.div`
    z-index: 10;
    overflow: hidden;
    width: 75%;
    height: 250px;
    padding-top: 28px;
    ${device.sm} {
        width: 74%;
        height: 380px;
        padding-top: 36px;
    }
    ${device.lg} {
        width: 74%;
        height: 390px;
        padding-top: 50px;
    }
`;

export const NonScreenContainer = styled.div`
    position: absolute;
    z-index: 15;
    width: calc(100%);
    left: 0px;
    top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${device.sm} {
        top: 70px;
        width: calc(100% - 500px + 250px);
    }
    ${device.md} {
        top: 80px;
        width: calc(100% - 500px + 170px);
    }
    ${device.lg} {
        top: 90px;
        width: calc(100% - 700px + 230px);
    }
    ${device.xl} {
        top: 100px;
        width: calc(100% - 700px + 190px);
    }
`;

export const MainClaimBox = styled.div`
    position: relative;
    left: 5%;
    width: 95%;
    ${device.sm} {
        position: relative;
        left: 6%;
        width: 94%;
    }
    ${device.md} {
        position: relative;
        left: 10%;
        width: 95%;
    }
`;

export const ClaimHeader = styled.h1`
    color: black;
    margin-bottom: 10px;
    font-size: 33px;
    ${device.sm} {
        font-size: 42px;
    }
    ${device.md} {
        font-size: 48px;
    }
    ${device.lg} {
        font-size: 65px;
    }
    ${device.xl} {
        font-size: 70px;
    }
`;

export const SubClaim = styled.p`
    color: #e28215;
    max-width: 300px;
    z-index: 100000;
    @media (max-width: 575.98px) {
        display: block;
        line-height: 16px;
    }
    ${device.md} {
        font-size: 18px;
        max-width: 400px;
    }
`;

export const LoginButtonContainer = styled.div`
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${device.sm} {
        margin-top: 50px;
    }
    ${device.md} {
        margin-top: 60px;
    }
`;

export const LoginButton = styled(Button)`
    border-radius: 2px;
    border: solid 2px;

    background: #e28215;
    color: white;
    border-color: #e28215;
    font-weight: 900;

    font-size: 24px;
    padding: 5px 10px;
    ${device.sm} {
        font-size: 30px;
        padding: 10px 20px;
    }
    ${device.md} {
        font-size: 36px;
        padding: 10px 30px;
    }

    transition: all 0.2s ease-in-out;

    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

    &:hover {
        background: #ffffff;
        color: #e28215;
        border-color: #e28215;
        -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
            0 11px 15px -7px rgba(0, 0, 0, 0.2);
        box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12),
            0 11px 15px -7px rgba(0, 0, 0, 0.2);

        font-size: 26px;
        padding: 5px 10px;
        ${device.sm} {
            margin-top: -6px;
            font-size: 34px;
            padding: 12px 22px;
        }
        ${device.md} {
            margin-top: -6px;
            font-size: 40px;
            padding: 12px 34px;
        }
    }
`;

export const LoginButtonSub = styled.div`
    margin-top: 5px;
    font-style: italic;
    font-size: 12px;
    color: #e28215;
    ${device.sm} {
        font-size: 14px;
    }
    ${device.md} {
        font-size: 18px;
    }
`;

export const ResponsiveContainer = styled(Container)`
    margin-top: 10px;
    ${device.sm} {
        margin-top: -10px;
    }
    ${device.md} {
        margin-top: -50px;
    }
    ${device.lg} {
        margin-top: 80px;
    }
    ${device.xl} {
        margin-top: -30px;
    }
`;

export const ResponsiveIcon = styled.img`
    height: 225px;
    width: 225px;
    padding: 10% 10% 10% 10%;
    ${device.sm} {
        height: 300px;
        width: 300px;
    }
    ${device.md} {
        height: 325px;
        width: 325px;
    }
    ${device.lg} {
        height: 325px;
        width: 325px;
    }
    ${device.xl} {
        height: 375x;
        width: 375px;
    }
`;

export const HoverRow = styled(Row)`
    margin-top: 10px;
    &:hover {
        box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
        background-color: #fff;
        border: 0 solid #edf2f9;
        border-radius: 0.375rem;
        z-index: 100;
    }
`;

export const HoverHeader = styled.h1`
    ${HoverRow}:hover & {
        color: #1575e2;
    }
`;
